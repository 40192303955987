<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside v-if="thumbnailb64">
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="thumbnailb64"
            height="80"
            :title="thumbnail"
          />
        </b-link>
      </b-media-aside>

      <b-media-body class="mt-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          id="uploadedFile"
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png"
          :hidden="true"
          plain
          @change="uploadFile"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          @click="removeThumbnailDisplay"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text> Allowed JPG or PNG. Max size of 800kB </b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer ref="profileUpdateRef" #default="{ invalid }">
      <b-form class="mt-2" @submit.prevent="submit">
        <b-row>
          <b-col sm="12">
            <validation-provider
              #default="{ errors }"
              name="Username"
              vid="Username"
              rules="required"
            >
              <b-form-group label="Username" label-for="account-username">
                <b-form-input
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  v-model="optionsLocal.username"
                  placeholder="Username"
                  name="username"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group label="First Name" label-for="account-first-name">
              <b-form-input
                v-model="optionsLocal.first_name"
                name="first_name"
                placeholder="First Name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Last Name" label-for="account-last-name">
              <b-form-input
                v-model="optionsLocal.last_name"
                name="last_name"
                placeholder="Last Name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <b-form-input
                v-model="optionsLocal.email"
                name="email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Company" label-for="account-company">
              <b-form-input
                disabled
                v-model="optionsLocal.companyName"
                name="company"
                placeholder="Company Name"
              />
            </b-form-group>
          </b-col>

          <!-- alert -->
          <!-- <b-col cols="12" class="mt-75">
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link"> Resend confirmation </b-link>
            </div>
          </b-alert>
        </b-col> -->
          <!--/ alert -->

          <b-col cols="12">
            <b-button
              :disabled="invalid"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
            >
              Save changes
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Reset
            </b-button> -->
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, onMounted } from "@vue/composition-api";
import { required, email } from "@validations";

import userApi from "@api/users";
import {  getImage } from "@/global-functions";

export default {
  components: {},
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const optionsLocal = ref(props.generalData);
    const profileFile = ref(null);
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    const thumbnail = ref(null);
    const thumbnailb64 = ref(null);
    function uploadFile(file) {
      const item = file.target.files[0] || null;

      if (item) {
        const reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onload = () => {
          thumbnail.value = item.name;
          thumbnailb64.value = reader.result;
        };
      } else {
        thumbnail.value = null;
        thumbnailb64.value = null;
      }
    }

    function removeThumbnailDisplay() {
      thumbnail.value = null;
      thumbnailb64.value = null;

      if (props.generalData.thumbnail) {
        const data = {
          model: "users",
          id: props.generalData.id,
          thumbnail: props.generalData.thumbnail,
        };

        thumbnailb64.value = getImage(data);
      }
    }

    function submit() {
      this.$refs.profileUpdateRef.validate().then((success) => {
        if (success) {
          const data = optionsLocal.value;

          if (thumbnail.value) {
            data.thumbnail = thumbnail.value;
            data.thumbnailbase64 = thumbnailb64.value;
          }

          userApi
            .update(data)
            .then(({ data }) => {
              if (data) {
                this.toastConfig();
              }
            })
            .catch(() => {
              //
            })
            .finally(() => {
              //
            });
        }
      });
    }

    onMounted(() => {
      removeThumbnailDisplay();
    });

    return {
      optionsLocal,
      profileFile,

      refInputEl,
      previewEl,
      inputImageRenderer,

      thumbnail,
      thumbnailb64,
      uploadFile,
      removeThumbnailDisplay,

      submit,

      //validations
      email,
      required,
    };
  },
};
</script>
